export const SET_ALBBTOKEN = 'SET_ALBBTOKEN'
export const SET_FILETYPE = 'SET_FILETYPE'
export const SET_USERINFO = 'SET_USERINFO'
export const SET_USERSUPDATE = 'SET_USERSUPDATE'
export const SET_COMPANYUSERS = 'SET_COMPANYUSERS'
export const SET_COMPANYINFO = 'SET_COMPANYINFO'
export const SET_COMPANYID = 'SET_COMPANYID'
export const SET_PROJECTID = 'SET_PROJECTID'
export const SET_COMPANYTYPE = 'SET_COMPANYTYPE'
export const INIT_WEBSOCKET = 'INIT_WEBSOCKET'
export const LOCK_RE_CONNECT = 'LOCK_RE_CONNECT'
export const SET_PING_INTERVAL = 'SET_PING_INTERVAL'
export const SET_DATA = 'SET_DATA'
export const SEND_MSG = 'SEND_MSG'
export const CLOSE_SOCKET = 'CLOSE_SOCKET'
export const SET_LINK_STATE = 'SET_LINK_STATE'
export const SET_SOCKET_FN = 'SET_SOCKET_FN'