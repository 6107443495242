<!--
 * @Author: BGig
 * @LastEditors: BGig
 * @LastEditTime: 2023-06-15 10:14:12
 * @FilePath: \smartmine2_web_vue\src\App.vue
 * @Description: App.vue
-->
<template>
  <transition name="fade">
    <keep-alive include="largeScreenMap">
      <router-view></router-view>
    </keep-alive>
  </transition>
</template>

<script>
export default {}
</script>
<style>
.driver .el-upload-dragger {
  width: 246px !important;
  height: 152px !important;
}

/* .el-drawer__container ::-webkit-scrollbar{
    display: none;
}  */

.bottomPagination {
  justify-content: flex-end;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
}
</style>
