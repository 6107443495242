/**
 * 全站路由配置
 * 代码中路由统一使用name属性跳转(不使用path属性)
 */

import Vue from 'vue'
import Router from 'vue-router'
import http from '@/utils/httpRequest'
import { isURL } from '@/utils/validate'

Vue.use(Router)

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  { path: '/404', component: _import('common/404'), name: '404', meta: { title: '404未找到' } },
  { path: '/login', component: _import('common/login'), name: 'login', meta: { title: '登录' } },
  { path: '/regist', component: _import('common/regist'), name: 'regist', meta: { title: '注册' } },
  { path: '/reset', component: _import('common/reset'), name: 'reset', meta: { title: '重置密码' } },
  { path: '/protocol', component: _import('common/protocol'), name: 'protocol', meta: { title: '平台协议' } },
  {
    path: '/select',
    name: 'select',
    component: _import('modules/company/select'),
    meta: { title: '选择公司' }
  },
  {
    path: '/createCompany',
    name: 'createCompany',
    component: _import('modules/company/createCompany'),
    meta: { title: '创建公司' }
  },
  {
    path: '/companyMain',
    name: 'companyMain',
    component: _import('modules/company/company-main'),
    meta: { title: '公司' },
    children: [
      // {
      //   path: 'createCompany',
      //   name: 'createCompany',
      //   component: _import('modules/company/createCompany'),
      //   meta: { title: '创建公司' }
      // },
      {
        path: 'addCompany',
        name: 'addCompany',
        component: _import('modules/company/addCompany'),
        meta: { title: '添加公司' }
      }
    ]
  },
  {
    path: '/largeScreenMap',
    component: _import('modules/dataCenter/LargeScreenMap'),
    name: 'largeScreenMap',
    meta: { title: '运力地图' }
  },
  {
    path: '/concreteMap',
    component: _import('modules/dataCenter/ConcreteMap'),
    name: 'concreteMap',
    meta: { title: '运力地图' }
  },
  {
    path: '/largeScreenTrack',
    name: 'largeScreenTrack',
    component: _import('modules/dataCenter/LargeScreenTrack'),
    meta: { title: '货车轨迹' }
  },
  {
    path: '/productionDispatching',
    component: _import('modules/productionCenter/ProductionDispatching'),
    name: 'productionDispatching',
    meta: { title: '生产调度' }
  },
  {
    path: '/visualWarehouse',
    component: _import('modules/businessCenter/VisualWarehouse'),
    name: 'visualWarehouse',
    meta: { title: '生产调度' }
  },
  {
    path: '/executeProductionTasks',
    component: _import('modules/productionCenter/ExecuteProductionTasks'),
    name: 'executeProductionTasks',
    meta: { title: '生产任务' }
  },
  {
    path: '/controlProduction',
    component: _import('modules/productionCenter/ControlProduction'),
    name: 'controlProduction',
    meta: { title: '生产控制' }
  }
]

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  component: _import('main'),
  name: 'main',
  redirect: { name: 'login' },
  meta: { title: '主入口整体布局' },
  children: [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    { path: '/home', component: _import('common/home'), name: 'home', meta: { title: '首页' } },
    { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
    { path: '/personInfo', component: _import('modules/person/personInfo'), name: 'personInfo', meta: { title: '个人中心' } },
    {
      path: '/demo-echarts',
      component: _import('demo/echarts'),
      name: 'demo-echarts',
      meta: { title: 'demo-echarts', isTab: true }
    },
    {
      path: '/welcome',
      component: _import('modules/welcome/Welcome'),
      name: 'welcome',
      meta: { title: '欢迎' }
    }
    // {
    //   path: '/demo-ueditor',
    //   component: _import('demo/ueditor'),
    //   name: 'demo-ueditor',
    //   meta: { title: 'demo-ueditor', isTab: true }
    // }
  ],
  beforeEnter: function (to, from, next) {
    // let token = Vue.cookie.get('token')
    // if (!token || !/\S/.test(token)) {
    //   next({name: 'login'})
    // }
    next()
  }
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to) === 'global') {
    const routeList = []
    routeList.push({ title: to.meta.title, parentId: to.meta.parentId, menuId: to.meta.menuId }) // 面包屑页面文字提示title
    to.meta.routeList = routeList
    next()
  } else {
    const url = sessionStorage.getItem('isBranch') ? '/sys/menu/son/nav' : '/sys/menu/client'
    http({
      url: http.adornUrl(url),
      method: 'get',
      params: http.adornParams({
        companyId: localStorage.getItem('companyId'),
        client: 0
      })
    }).then(({ data }) => {
      if (data && data.code === 200) {
        fnAddDynamicMenuRoutes(data.menuList)
        router.options.isAddDynamicMenuRoutes = true
        sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
        sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
        next({ ...to, replace: true })
      } else {
        sessionStorage.setItem('menuList', '[]')
        sessionStorage.setItem('permissions', '[]')
        next({ name: 'login', replace: true })
      }
    })
  }
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    window.location.reload()
    // router.replace(router.history.pending.fullPath);
  } else {
    console.log(error)
  }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType (route) {
  for (let i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global'
    } else if (globalRoutes[i].children) {
      // console.log('如果不为全局路由一级路径，进入该循环判断全局路由的子路径')
      for (let j = 0; j < globalRoutes[i].children.length; j++) {
        if (route.path === (globalRoutes[i].path + '/' + globalRoutes[i].children[j].path)) {
          // console.log(globalRoutes[i].path + '/' + globalRoutes[i].children[j].path)
          // console.log('走的global-children')
          return 'global'
        }
      }
    }
  }
  return 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
  const isSwitch = localStorage.getItem('switch')
  let temp = []
  for (let i = 0; i < menuList.length; i++) { // type为1表示是一个链接，反之没有链接
    if (menuList[i].list && menuList[i].list.length >= 1 && menuList[i].type !== 1) { // 一级导航栏，无链接
      temp = temp.concat(menuList[i].list)
    } else if (menuList[i].list && menuList[i].list.length >= 1 && menuList[i].type === 1) { // 二级导航栏，有链接
      temp = temp.concat(menuList[i].list)
      menuList[i].url = menuList[i].url.replace(/^\//, '')
      var route = {}
      if (menuList[i].permissions) {
        var perms = menuList[i].permissions.split(':')
        route = {
          path: menuList[i].url.replace('/', '-') + '/:' + perms[0],
          component: _import(`modules/${menuList[i].url}`) || null,
          name: menuList[i].url.replace('/', '-'),
          meta: {
            menuId: menuList[i].hidden === 2 ? menuList[i].parentId : menuList[i].menuId,
            title: isSwitch === '1' ? menuList[i].fakeName : menuList[i].name,
            isDynamic: true,
            isTab: true,
            iframeUrl: '',
            hidden: menuList[i].hidden,
            type: menuList[i].type,
            parentId: menuList[i].parentId,
            keepAlive: true
          }
        }
      } else {
        route = {
          path: menuList[i].url.replace('/', '-'),
          component: _import(`modules/${menuList[i].url}`) || null,
          name: menuList[i].url.replace('/', '-'),
          meta: {
            menuId: menuList[i].hidden === 2 ? menuList[i].parentId : menuList[i].menuId,
            title: isSwitch === '1' ? menuList[i].fakeName : menuList[i].name,
            isDynamic: true,
            isTab: true,
            iframeUrl: '',
            hidden: menuList[i].hidden,
            type: menuList[i].type,
            parentId: menuList[i].parentId,
            keepAlive: false
          }
        }
      }
      routes.push(route)
    } else if (menuList[i].url && /\S/.test(menuList[i].url)) { // S是匹配所有字符，s则反之   // 三级页面，添加，修改，详情
      menuList[i].url = menuList[i].url.replace(/^\//, '')
      route = {}
      if (menuList[i].permissions) {
        perms = menuList[i].permissions.split(':')
        route = {
          path: menuList[i].url.replace('/', '-') + '/:' + perms[0],
          component: null,
          name: menuList[i].url.replace('/', '-'),
          meta: {
            menuId: menuList[i].hidden === 2 ? menuList[i].parentId : menuList[i].menuId,
            title: isSwitch === '1' ? menuList[i].fakeName : menuList[i].name,
            isDynamic: true,
            isTab: true,
            iframeUrl: '',
            hidden: menuList[i].hidden,
            type: menuList[i].type,
            parentId: menuList[i].parentId,
            keepAlive: false
          }
        }
      } else {
        route = {
          path: menuList[i].url.replace('/', '-'),
          component: null,
          name: menuList[i].url.replace('/', '-'),
          meta: {
            menuId: menuList[i].hidden === 2 ? menuList[i].parentId : menuList[i].menuId,
            title: isSwitch === '1' ? menuList[i].fakeName : menuList[i].name,
            isDynamic: true,
            isTab: true,
            iframeUrl: '',
            hidden: menuList[i].hidden,
            type: menuList[i].type,
            parentId: menuList[i].parentId,
            keepAlive: false
          }
        }
      }
      // url以http[s]://开头, 通过iframe展示
      if (isURL(menuList[i].url)) {
        route.path = `i-${menuList[i].menuId}`
        route.name = `i-${menuList[i].menuId}`
        route.meta.iframeUrl = menuList[i].url
      } else {
        try {
          route.component = _import(`modules/${menuList[i].url}`) || null
        } catch (e) {
        }
      }
      routes.push(route)
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes)
  } else {
    mainRoutes.name = 'main-dynamic'
    mainRoutes.children = routes
    router.addRoutes([
      mainRoutes,
      { path: '*', redirect: { name: '404' } }
    ])
    sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
    // console.log('\n')
    console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
    console.log(mainRoutes)
    console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
  }
}

export default router
