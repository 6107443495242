import dictionaryApi from '@/api/dictionaryApi' //获取字典
const dictionaryData = {
  state: {
    dicData: []
  },
  mutations: {
    setDictionaryData(state, data) {
      state.dicData = data;
    }
  },
  actions: {
    fetchDictionaryData({ commit },{ type,companyId }) {
      return dictionaryApi.getDictionary({
        companyId: companyId,
        type: type
      })
        .then(res => {
          if (res.data.code === 200) {
            commit('setDictionaryData', res.data.data?.[0]?.children || []);
          } else {
            this.$message.error(res.data.msg ? res.data.msg : '获取失败')
          }
        })
        .catch(error => {
          this.$message.error('获取失败')
        });
    }
  }
}
export default dictionaryData