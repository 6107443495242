export function replaceWord (word) {
  let labelArr = [
    'sellPrice',
    'price',
    'discount',
    'discountPrice',
    'total',
    'totalPrice',
    'orderPrice',
    'totalSpend',
    'lastSpendTime',
    'income',
    'alreadyIncome',
    'paymented',
    'unpaid',
    'settle',
    'refund',
    'money',
    'projectMoney',
    'royalty',
    'myRoyalty',
    'staffRoyalty',
    'royaltyDetail',
    'settlePrice',
    'royaltyDateRange',
    'royaltyDate',
    'cashier',
    'todayRoyalty',
    'settlementRecord',
    'closeStatus',
    'notClose',
    'allClose',
    'someClose',
    'close',
    'recharge',
    'rechargeAmount',
    'closeCustomer',
    '订单数', '元', '总金额', '销售金额', '消费', '订单', '付款', '支付', '退款', '已付', '金额', '价格', '折扣', '消费金额',
    'frontMoney',
    'payStatus',
    'invoiceNumber']
  let valueArr = [
    ['销售价格', '颜值'],
    ['价格', '颜值'],
    ['折扣', '满意度'],
    ['折后价', '实得颜值'],
    ['总价', '总颜值'],
    ['消费总额', '颜值总计'],
    ['订单金额', '总颜值'],
    ['累计消费', '累计颜值'],
    ['最近消费时间', '最近获颜时间'],
    ['应收', '应得颜值'],
    ['已收', '已得颜值'],
    ['已支付', '已获取'],
    ['未支付', '待获取'],
    ['结账', '获取'],
    ['退款', '返还'],
    ['金额', '积分'],
    ['金额', '颜值'],
    ['提成金额', '积分'],
    ['我的提成', '我的积分'],
    ['员工提成', '员工积分'],
    ['提成明细', '积分明细'],
    ['结账金额', '总颜值'],
    ['提成日期区间', '积分日期区间'],
    ['提成日期', '日期'],
    ['收银台', '颜值管理'],
    ['今日提成', '今日积分'],
    ['结算记录', '颜值记录'],
    ['结算状态', '获取状态'],
    ['未结算', '待获取'],
    ['已结算', '已获取'],
    ['部分结算', '部分获取'],
    ['结算', '获取'],
    ['充值', '增加额度'],
    ['会员额度充值', '增加额度'],
    ['充值额度', '额度'],
    ['结算客户', '已获颜客户'],
    ['订单数', '获取笔数'],
    ['元', ''],
    ['总金额', '总颜值'],
    ['销售金额', '总颜值'],
    ['消费', '获取'],
    ['订单', '获取'],
    ['付款', '获取'],
    ['支付', '获取'],
    ['退款', '返还'],
    ['已付', '已获'],
    ['金额', '颜值'],
    ['价格', '颜值'],
    ['折扣', '满意度'],
    ['消费金额', '获取颜值'],
    ['定金', '承诺值'],
    ['缴费状态', '颜值状态'],
    ['发票号', '凭证号']
  ]
  let index = labelArr.indexOf(word)
  if (index !== -1) {
    return valueArr[index]
  } else {
    return word
  }
}

export function isRMB () {
  return Number(localStorage.getItem('switch')) ? ' ' : '￥'
}
