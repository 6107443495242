// 运单状态
export function getWaybillStatus(val) {
  const map = { 0: ['danger', '待运输'], 1: ['info', '装载中'], 2: ['primary', '运输中'], 3: ['success', '已完成'],9: ['success', '机操已确认'],'-1':['warning','已作废'] }
  return map[val]
}
// 送货单状态
export function getDeliveryStatus(val) {
  const map = { 0: ['danger', '待装车'], 1: ['info', '装载中'], 2: ['primary', '运输中'], 3: ['success', '已到站'],4: ['success', '已卸货'],5: ['success', '已完成 '],9: ['success', '机操已确认'],'-1':['warning','已作废'] }
  return map[val]
} 
// 计划任务状态
export function getTaskStatus(val) {
  const map = { 0: ['danger', '待下发'], 1: ['info', '待确认'], 2: ['primary', '生产中'], 3: ['success', '已完成'],4: ['warning', '暂停中'],5: ['warning', '预创建'],6: ['warning', '待确认'] }
  return map[val]
} 
// 供应链商混采购任务状态
export function getSupplyTaskStatus(val) {
  const map = { 0: ['danger', '待下发'], 1: ['info', '待确认'], 2: ['primary', '生产中'], 3: ['success', '已完成'],4: ['warning', '暂停中'],5: ['warning', '预创建'],6: ['primary', '已确认提货'] }
  return map[val]
} 
// 工程状态
export function getProjectStatus(val) {
  const map = { 0: ['danger', '待下发'], 1: ['warning', '暂停中'], 2: ['primary', '进行中'], 3: ['success', '已完工'] }
  return map[val]
}

// 材料类型
export function getDistinguishName(val) {
  const map = { 0: '骨料', 1: '商混', 2: '原材料', 3: '耗材', 4: '剩料', 5: '废料' }
  return map[val]
}
