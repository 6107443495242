import http from '@/utils/httpRequest'

export default {
  // 创建字典
  dictionaryCreate (form) {
    let obj = http.adornData(form)
    return http.post(http.adornUrl('/dictionary/create'), obj)
  },
  // 根据字典类型获取具体的值
  getDictionary (data) {
    return http.get(http.adornUrl('/dictionary/list/type'), {params: http.adornParams(data)})
  },
  // 删除字典
  deleteDictionary (data) {
    return http.delete(http.adornUrl('/dictionary/delete'), {params: http.adornParams(data)})
  },
  // 更新字典
  dictionaryUpdate (data) {
    let obj = http.adornData(data)
    return http.post(http.adornUrl('/dictionary/create'), obj)
  },
  // 拿user下拉列表
  getUserSelect (data) {
    return http.get(http.adornUrl('/sys/user/list'), {params: http.adornParams(data)})
  },
  // 拿项目下拉列表
  getProjectSelect (data) {
    return http.get(http.adornUrl('/project/list/anyone'), {params: http.adornParams(data)})
  },
  // 拿模板下拉列表
  getTempSelect (data) {
    return http.get(http.adornUrl('/visit/template/list'), {params: http.adornParams(data)})
  }
}
