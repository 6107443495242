/**
 * 校验邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 校验手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[189])\d{8}$/.test(s)
}

/**
 * 校验提成比例
 * @param {*} s
 */
export function isCommissionProportion (s) {
  return /^(0|([1-9]\d{0,2}))$/.test(s)
}
export function isCommissionProportions (s) {
  return /^(0|([1-9]\d{0,1}))$/.test(s)
}

/**
 * 校验前缀编号
 * @param {*} s
 */
export function isBegainNum (s) {
  return /^[0-9A-Za-z]{1,20}$/.test(s)
}
// 6位长度，数字或字母的组合
export function isSixNum (s) {
  return /^[0-9A-Za-z]{1,6}$/.test(s)
}

/**
 * 校验电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8,9}$/.test(s)
}

/**
 * 校验固定电话号码
 * @param {*} s
 */
export function isTelePhone (s) {
  return /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/.test(s)
}

/**
 * 校验正整数
 * @param {*} s
 */
export function isInt (s) {
  return /^[0-9]\d*$/.test(s)
}

/**
 * 校验 URL 地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

// 110/70的正则
export function ismmHg (s) {
  return /^\d{1,9}\/\d{1,9}$/.test(s)
}
// 1-2位小数的正实数
export function isTiwen (s) {
  return /^[0-9]+(.[0-9]{1,2})?$/.test(s)
}
// 正整数
export function isXinlv (s) {
  return /^\+?[1-9][0-9]*$/.test(s)
}
// 数字
export function isNumber (s) {
  return /^\d+(?=\.{0,1}\d+$|$)/.test(s)
}
